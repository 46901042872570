<template>
  <div>
    <div class="md-layout md-alignment-bottom-left">
      <div class="md-layout-item md-size-50 md-size-medium-100 nopadding">
        <autocomplete-material
          :search="search"
          :options="users"
          :getResultValue="getResultValue"
          label="Participants"
          :formatList="(result) => result.displayName"
          :handleSubmit="handleSubmit"
          :noResults="noResults"
          :valueProps="userSelected.displayName"
          :disabled="isUserSelected"
        />
      </div>
      <div class="md-layout-item md-size-40 md-size-medium-90 nopadding">
        <role-input :rolesOptions="rolesOptions" :rolesProps="userSelected.roles" @handleChange="handleRolesChange" :isUserSelected="isUserSelected"/>
      </div>
      <div class="md-layout-item md-size-10 md-size-medium-10 nopadding">

        <span v-if="isUserSelected" @click="removeMember">
              <md-icon class="icon-action icon-delete">delete</md-icon>
              <md-tooltip md-delay="300">Remove Participant</md-tooltip>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AutocompleteMaterial from '../AutocompleteMaterial/AutocompleteMaterial.vue';
import RoleInput from './RoleInput.vue';

export default {
  components: {
    AutocompleteMaterial,
    RoleInput,
  },
  props: {
    rolesOptions: Array,
    users: Array,
    user: Object,
  },
  data() {
    return {
      results: [],
      inputValue: '',
      isUserSelected: false,
      userSelected: this.user,
    };
  },
  mounted() {
    if (this.user.displayName) {
      this.isUserSelected = true;
    }
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.userSelected = newVal;
        if (newVal.displayName && newVal.displayName !== '') {
          this.isUserSelected = true;
        } else {
          this.isUserSelected = false;
        }
      }
    },
  },
  computed: {
    noResults() {
      return this.inputValue !== '' && this.results.length === 0;
    },
  },
  methods: {
    search(input) {
      this.inputValue = input;
      if (input.length < 1) {
        return [];
      }
      this.results = this.users.filter((user) => user.displayName.toLowerCase().includes(input.toLowerCase()));
      return this.results;
    },
    fetchUser() {
      return this.$store.getters['users/GET_USERS'];
    },
    getResultValue(result) {
      return result.displayName;
    },
    handleSubmit(result) {
      this.isUserSelected = true;
      this.userSelected = {
        ...result,
        roles: this.userSelected.roles,
      };
    },
    handleRolesChange(roles) {
      this.userSelected = {
        ...this.userSelected,
        roles,
      };
      this.$emit('actionUser', this.userSelected);
    },
    removeMember() {
      this.$emit('removeUser', this.userSelected);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
