<template>
  <div>
    <roles-selector-modal
        v-if="openRolesModal"
        :rolesProps="roles"
        :rolesOptions="rolesOptions"
        @close="handleCloseRolesModal"
        @rolesChange="handleRolesChange"
      />
    <div class="form-field" @click="handleOpenRolesModal">
      <div class="form-field__control">
        <div id="roles"
          class="form-field__input roles"
          v-bind:class="{ rolesInfo : !isUserSelected }"
          @focus="handleFocus"
          @blur="handleBlur"
          disabled
        >
        {{rolesString}}
        </div>
        <label for="roles" class="form-field__label">Roles</label>
      </div>
    </div>
  </div>
</template>
<script>
import RolesSelectorModal from '../Modals/RolesSelectorModal.vue';

export default {
  components: {
    RolesSelectorModal,
  },
  props: {
    rolesOptions: Array,
    rolesProps: Array,
    isUserSelected: Boolean,
  },
  data() {
    return {
      roles: this.rolesProps ? this.rolesProps : [],
      openRolesModal: false,
    };
  },
  watch: {
    rolesProps(newVal) {
      if (newVal) {
        this.roles = this.rolesProps ? this.rolesProps : [];
      }
    },
    isUserSelected(newVal) {
      if (newVal && this.rolesProps.length === 0) {
        this.openRolesModal = true;
      }
    },
  },
  computed: {
    rolesString() {
      if (this.isUserSelected) {
        if (this.roles && this.roles.length > 0) {
          return this.roles.map((role) => role[0].toUpperCase() + role.slice(1)).join(', ');
        }
        return '';
      }
      return 'Choose a user first';
    },
  },
  methods: {
    handleRolesChange(roles) {
      this.handleCloseRolesModal();
      this.roles = roles;
      this.$emit('handleChange', this.roles);
    },
    handleOpenRolesModal() {
      if (this.isUserSelected) this.openRolesModal = true;
    },
    handleCloseRolesModal() {
      this.openRolesModal = false;
    },
    handleFocus() {
      this.focused = true;
    },
    handleBlur() {
      this.focused = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.roles {
  min-height: 32px;
  text-align: left;
}
.rolesInfo {
  color: rgb(170, 170, 170);
}
</style>
