<template>
  <div>
    <md-card md-with-hover class="project-card md-card-stats" :class="{ clicknotallowed: !isCurrentUserParticipant }">
      <div @click="onProjectClicked">
        <md-card-header class="md-card-header-icon" :class="getHealthClass">
          <div class="card-container">
            <div class="state-icon-container" :class="getHealthClassBackground">
              <i class="fa state-icon" :class="getStateIcon"></i>
              <md-tooltip md-direction="top" v-html="getTooltip"></md-tooltip>
            </div>
            <div class="content-header-container">
              <div class="md-layout">
                <div class="md-layout-item">
                  <h6 class="state-name">
                    {{ project.currentState || "completed" }}
                  </h6>
                </div>
                <div class="md-layout-item">
                  <h4 class="project-title">
                    {{ project && project.name ? project.name.toUpperCase() : "" }}
                  </h4>
                </div>
                <div class="md-layout-item md-size-80">
                  <div class="sparkline-container ">
                    <div class="sparkline">
                      <sparkline :sparklineData="getSparklineData"></sparkline>
                    </div>
                  </div>
                </div>
                <div v-if="project.isArchived" class="md-layout-item md-size-20 nopadding">
                  <div class="archive-icon-container nopadding">
                    <i id="archive-icon" class="fa fa-archive" aria-hidden="true">
                      <md-tooltip md-direction="top" md-delay="200">Project Archived</md-tooltip>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="tags-container">
            <i class="fas fa-hashtag tags-icon" aria-hidden="true">
              <md-tooltip md-direction="right">Tags</md-tooltip>
            </i>
            <div v-if="hasTags" class="align-content-left">
              <md-chip
                class="md-info custom-chip"
                v-for="tag in project.tags"
                :key="tag.name"
                md-disabled
                >{{ tag.name }}</md-chip
              >
            </div>
            <div v-else>
              <div class="discrete">no tag</div>
            </div>
          </div>
          <div class="users-container ">
            <i class="far fa-user tags-icon">
              <md-tooltip md-direction="right">Team</md-tooltip>
            </i>
            <div v-if="hasUsers" class="align-content-left" aria-hidden="true">
              <md-chip
                class="md-primary custom-chip"
                v-for="user in project.users"
                :key="user.email"
                md-disabled
                >{{ user.displayName }}</md-chip
              >
            </div>
            <div v-else>
              <div class="discrete">no participant</div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions class="project-card-footer">
          <div class="project-type">
            <div class="project-type-label nopadding">{{ getProjectType }}</div>
          </div>
          <div class="timestamp">
            <md-icon>access_time</md-icon>
            updated
            {{ project.lastUpdate | moment("from") }}
          </div>
        </md-card-actions>
      </div>
    </md-card>
  </div>
</template>

<script>
import {
  utilsGetHealthClass,
  utilsGetHealthClassBackground,
} from '@/utils/utils';
import Sparkline from '@/components/Avalia/ProjectsOverviewTabs/Sparkline/Sparkline.vue';
import notify from '@/utils/notificationsUtils';

export default {
  name: 'project-overview-card',
  components: {
    Sparkline,
  },
  props: {
    project: Object,
  },
  data() {
    return {};
  },
  computed: {
    getHealthClass() {
      return utilsGetHealthClass(this.project.health);
    },
    getHealthClassBackground() {
      return utilsGetHealthClassBackground(this.project.health);
    },
    getTooltip() {
      return `state: <b>${this.project.currentState}</b></br>health: <b>${this.project.health}</b>`;
    },
    getStateIcon() {
      return this.project.currentStateIcon || 'fa-trophy';
    },
    hasUsers() {
      return this.project.users.length;
    },
    hasTags() {
      return this.project.tags.length;
    },
    getIsArchived() {
      return this.project.isArchived;
    },
    getProjectType() {
      return `${this.project.caseType} v${this.project.caseVersion}`;
    },
    getSparklineData() {
      // return [0, 3, 5, 7, 0, 0, 0, 7, 8, 5, 3, 0, 15, 16, 22, 30, 0, 0, 0, 0, 1, 4, 9, 2, 5, 2, 0, 6, 3, 8];
      return this.project.sparkline;
    },
    currentUser() {
      return this.$store.getters['users/GET_CURRENT_USER'];
    },
    isCurrentUserParticipant() {
      return this.project.users.map((user) => user.email).includes(this.currentUser.sub);
    },
  },
  created() {
    // On created
  },
  methods: {
    onProjectClicked(e) {
      if (this.project.id && this.isCurrentUserParticipant) {
        this.$emit('projectClicked', {
          event: e,
          project: this.project,
        });
      } else {
        const warningMessage = 'You can\'t view the details of a project you\'re not participating in';
        notify.notifyWarning(warningMessage, this);
        e.stopPropagation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.project-header {
  display: -webkit-box;
  justify-content: space-between;
}

.state-name {
  // margin: 0.4rem 0rem 0rem 0.8rem;
  font-size: 0.9rem;
  margin: 0.4rem 0rem 0rem 0rem;
  text-align: left;
}

.project-card {
  min-height: 13em;
  // margin-bottom: 1rem;
  // margin-top: 1rem;
}

.md-chips.md-field {
  padding-top: 0rem;
}
.users-container {
  display: -webkit-box;
  padding-top: 0.5rem;
  justify-content: flex-start;

  .users-icon {
    padding-right: 0.5rem;
  }
  .user {
    padding-top: 0.2rem;
  }
}
.tags-container {
  display: -webkit-box;
}
.align-content-left {
  text-align: left;
}
.tags-icon {
  padding-right: 0.5rem;
  position: relative;
  top: 0.2rem;
}
.timestamp-container {
  padding: 0rem;
}
.timestamp {
  text-align: right;
  position: relative;
  bottom: 0rem;

  i {
    font-size: 16px !important;
  }

}
div.md-card-content {
  padding-top: 0rem;
}

.custom-chip {
  margin-left: 0px;
  margin-right: 4px;
}
.discrete {
  color: $avalia-black;
  padding-top: 0.2rem;
}
.project-card-footer {
  display: flex;
  padding-top: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin: 0.2rem 0rem 0.4rem;

  .project-type {
  }

  .timestamp {
    margin-left: auto;
  }

}
.project-type-label {
  // color: $avalia-black;
  font-size: 14px;
}
.project-title {
  font-weight: 400 !important;
  color: $avalia-black;
  margin-left: auto;
  margin-top: 0.4rem;
  margin-bottom: 0rem;
}
.is-archived-container {
  display: -webkit-box;
  justify-content: flex-start;
  padding-top: 0rem;
  .archived-icon-container {
    margin: 0.2rem 0rem 0rem 0rem;
  }
  h6 {
    //margin: 0.2rem 0.4rem 0.4rem;
    margin: 0.4rem 0rem 0rem 0.4rem;
  }
}
.health-container {
  text-align: right;
}
.title-container {
  text-align: right;
}
.sparkline-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.sparkline {
  height: 2.5rem;
  max-width: 12rem;
  width: 100%;
}
.title-state-container {
  display: flex;
  justify-content: space-between;
}
#archive-icon  {
  color: $avalia-black;
  font-size: 1.2em!important;
  margin: -15px;
}
.archive-icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.state-icon-container {
  margin-top: -20px;
  width: 76px;
  height: 76px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.state-icon {
  color: #fff;
  width: 56px!important;
  height: 56px!important;
}
.card-container {
  display: flex;
  width: 100%;
}
.content-header-container {
  flex: 1;
}
.md-card-content {
  margin-top: 10px;
}

</style>
