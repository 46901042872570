<template>
  <div>
    <md-card class="table-view-cards">
      <md-card-content>
        <div>
          <p>
            This feature is not ready yet. You can already have a look at the
            draft version of the slide deck, but it does not contain a lot of
            information at this stage.
          </p>
          <p>
            <a
              v-if="jwtRelayUrl != undefined"
              :href="deckUrl"
              target="_blank"
              >Show the deck</a
            >
          </p>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import api from '@/api/api';

export default {
  data() {
    return {
      jwtRelayUrl: undefined,
    };
  },
  computed: {
    deckUrl() {
      const config = this.$store.getters['config/GET_CORE_CONFIG'];
      const slidesAppUrl = config.applications.slides.externalUrl;
      let target;
      if (!api.assess.defaults.baseURL.startsWith('http')) {
        // eslint-disable-next-line no-restricted-globals
        target = encodeURIComponent(`${location.origin}${api.assess.defaults.baseURL}/slides`);
      } else {
        target = encodeURIComponent(`${api.assess.defaults.baseURL}/slides`);
      }
      return `${slidesAppUrl}/decks/remote/1?url=${target}&relayUrl=${this.jwtRelayUrl}`;
    },
  },
  mounted() {
    this.$track('fe_teaser_slideShowAllProjects');

    api.assess.get('/sso/relay').then((response) => {
      if (!api.assess.defaults.baseURL.startsWith('http')) {
      // eslint-disable-next-line no-restricted-globals
        this.jwtRelayUrl = `${location.origin}${api.assess.defaults.baseURL}/sso/relay/${response.data}/jwt`;
      } else {
        this.jwtRelayUrl = `${api.assess.defaults.baseURL}/sso/relay/${response.data}/jwt`;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.table-view-cards {
  margin-top: 1rem;
}
</style>
