<template>
  <modal-small @close="hide">
    <template slot="header">
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="hide"
      >
        <md-icon>clear</md-icon>
      </md-button>
      <h4 class="modal-title">{{ title }}</h4>
    </template>

    <template slot="body" >
      <div class="md-layout roles-modal">
        <div class="md-layout-item md-layout md-size-100">
          <div v-for="role in rolesOptions" :key="role.id" class="md-layout-item md-size-100">
            <md-checkbox v-model="roles" :value="role">{{formatString(role)}}</md-checkbox>
          </div>
        </div>
      </div>

      <div
        class="md-layout-item md-size-100 mx-auto md-medium-size-100 buttons-container"
      >
      <md-button
          class="md-danger modal-button md-sm cancel-button"
          @click="hide"
          >Cancel</md-button
        >
        <md-button class="md-success md-sm confirm-button"
          @click="handleRolesChange">
          Confirm
        </md-button>
      </div>
    </template>
  </modal-small>
</template>

<script>
import ModalSmall from '@/components/Avalia/Modals/ModalSmall/ModalSmall.vue';

export default {
  components: { ModalSmall },
  props: {
    rolesProps: Array,
    rolesOptions: Array,
    userFirstName: {
      type: String,
      default: () => 'User',
    },
  },
  watch: {
    rolesProps(newVal) {
      if (newVal) {
        this.roles = this.rolesProps ? JSON.parse(JSON.stringify(this.rolesProps)) : []; // Deep copy
      }
    },
  },
  computed: {
    title() {
      return `Choose ${this.userFirstName}'s Roles`;
    },
  },
  data() {
    return {
      roles: this.rolesProps ? JSON.parse(JSON.stringify(this.rolesProps)) : [], // Deep copy
    };
  },
  methods: {
    handleRolesChange() {
      const rolesEmit = this.roles.map((role) => this.rolesOptions.find((roleOption) => roleOption === role));
      this.$emit('rolesChange', rolesEmit);
    },
    hide() {
      this.$emit('close');
    },
    formatString(value) {
      return value[0].toUpperCase() + value.slice(1);
    },
  },
};
</script>
<style lang="scss">
.roles-modal {
  width: 300px;
  text-align: left;
}
</style>
<style lang="scss" scoped>
.confirm-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.cancel-button {
  color: #fe0600 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #fe0600 !important;
    opacity: 0.8 !important;
    border-color: #fe0600 !important;
  }
}
.modal-button {
  margin-right: 0.4rem;
}
</style>
