<template>
  <autocomplete
    :search="search"
    :get-result-value="getResultValue"
    aria-label="Search for a member of the team of the project"
    auto-select
    :default-value="value"
    @submit="handleSubmit"
    ref="autocomplete"
  >
    <template
      #default="{
        rootProps,
        inputProps,
        inputListeners,
        resultListProps,
        resultListListeners,
        results,
        resultProps
      }"
    >
      <div v-bind="rootProps">
        <div class="form-field">
          <div class="form-field__control">
            <input
              v-bind="inputProps"
              type="text"
              :value="inputProps.value"
              placeholder=" "
              v-on="inputListeners"
              class="form-field__input"
              @focus="handleFocus"
              @blur="handleBlur"
              :disabled="disabled"
            />
            <label for="autocomplete-participant" class="form-field__label">Participant</label>
            <div class="form-field__bar"></div>
          </div>
        </div>
        <ul
          v-if="noResults"
          class="autocomplete-result-list"
          style="position: absolute; z-index: 1; width: 100%; top: 100%;"
        >
          <li class="autocomplete-result">
            No results found
          </li>
        </ul>
        <ul v-bind="resultListProps" v-on="resultListListeners">
          <li
            v-for="(result, index) in results"
            :key="resultProps[index].id"
            v-bind="resultProps[index]"
          >
            {{ result.displayName }} : {{ result.email }}
          </li>
        </ul>
      </div>
    </template>
  </autocomplete>
</template>
<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '../../../assets/scss/avalia_autocomplete.scss';

export default {
  components: {
    Autocomplete,
  },
  props: {
    search: Function,
    options: [Array],
    getResultValue: Function,
    label: String,
    formatList: Function,
    handleSubmit: Function,
    noResults: Boolean,
    valueProps: {
      type: String,
      default: '',
    },
    disabled: Boolean,
  },
  watch: {
    valueProps(newVal) {
      if (newVal !== null) {
        this.value = newVal;
        this.$refs.autocomplete.value = newVal;
      }
    },
  },
  data() {
    return {
      value: this.valueProps,
    };
  },
  methods: {
    handleFocus() {
      this.focused = true;
    },
    handleBlur() {
      this.focused = false;
    },
  },
};
</script>
