<template>
  <modal @close="hide">
    <template slot="header">
      <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hide">
        <md-icon>clear</md-icon>
      </md-button>
      <h4 class="modal-title">{{ title }}</h4>
    </template>

    <template slot="body">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="form-horizontal" @submit.prevent="handleSubmit(onSubmit)">
          <div class="md-layout">
            <!-- Project Name -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <ValidationProvider name="projectName" rules="required" v-slot="{ passed, failed }"
                ><md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>Project Name *</label>
                  <md-input v-model="project.name" type="text" ref="projectNameField"></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error indicator-icon" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success indicator-icon" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <!-- Project Type -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <ValidationProvider name="projectType" rules="required" v-slot="{ passed, failed }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label for="type">Project Type *</label>
                  <md-select id="type" name="type" v-model="project.type">
                    <md-option
                      v-for="caseDefinition in caseDefinitions"
                      :key="caseDefinition.id"
                      :value="caseDefinition.key"
                      :label="caseDefinition.name"
                      >{{ caseDefinition.name }}</md-option
                    >
                  </md-select>
                  <slide-y-down-transition>
                    <md-icon class="error indicator-icon" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success indicator-icon" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <!-- Description -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <md-field class="description-field">
                <label>Description</label>
                <md-textarea
                  :maxlength="2000"
                  :md-autogrow="true"
                  v-model="project.description"
                  type="text"
                />
              </md-field>
            </div>
            <!-- Google Drive Link -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100 link-field">
              <md-field>
                <md-icon><i class="fab fa-google-drive"></i></md-icon>
                <label>Google Drive Link</label>
                <md-input v-model="project.driveLink" type="text" />
              </md-field>
            </div>
            <!-- Google Chat Link -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100 link-field">
              <md-field>
                <md-icon><i class="fas fa-comments"></i></md-icon>
                <label>Google Chat Link</label>
                <md-input v-model="project.chatLink" type="text" />
              </md-field>
            </div>
            <!-- Tags -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <div class="md-layout tags-field-container">
                <label class="md-layout-item md-size-10 md-form-label tags-label">Tags</label>
                <div class="md-layout-item md-size-90 tags-input nopadding">
                  <md-field class="tags-field">
                    <vue-tags-input
                      class="avalia-tags-input"
                      v-model="tag"
                      :tags="project.tags"
                      :autocomplete-items="autocompleteItems"
                      :placeholder="tagsInputPlaceholder"
                      @tags-changed="tagsChanged"
                      @before-adding-tag="newTagToValidate"
                    />
                  </md-field>
                </div>
              </div>
            </div>
            <!-- PARTICIPANTS -->
            <div class="md-layout-item md-size-100 md-medium-size-100 mx-auto participants-autocomplete">
                <team-manager
                  :teamProps="project.users"
                  :usersOptions="usersOptions"
                  @setTeam="setParticipants"
                  @addToUsersList="addToUsersOptions"
                  @removeFromUsersList="removeFromUsersOptions"
                  :rolesOptions="rolesOptions"
                  :currentUser="currentUser"
                />
            </div>
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100 buttons-container">
              <md-button class="md-danger modal-button md-sm cancel-button" @click="hide"
                >Cancel</md-button
              >
              <md-button class="md-success md-sm confirm-button" type="submit">
                Confirm
              </md-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions';
import VueTagsInput from '@johmun/vue-tags-input';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import { Modal } from '@/components/Template';
import TeamManager from '../TeamManager/TeamManager.vue';

extend('required', required);

export default {
  name: 'create-project-modal',
  components: {
    Modal, SlideYDownTransition, VueTagsInput, TeamManager,
  },
  props: {
    existingUsers: {
      type: Array,
      default: () => [],
    },
    existingProjectRoles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: 'Create Project',
      userSearchTerm: '',
      project: {
        name: '',
        type: '',
        description: '',
        driveLink: '',
        chatLink: '',
        users: [],
        tags: [],
      },
      currentUser: {},
      tagType: 'project tag',
      tagsInputPlaceholder: '',
      tag: '',
      autocompleteItems: [],
      debounce: null,
      usersOptions: [],
      selectedUser: {},
      rolesOptions: [],
      selectedRoles: [],
      userSelected: false,
      projectCreatorRoles: ['curator'],
    };
  },
  computed: {
    allTags() {
      return this.$store.getters['tags/GET_PROJECT_TAGS'];
    },
    getCurrentUserEmail() {
      return this.$store.getters['users/GET_CURRENT_USER_EMAIL'];
    },
    caseDefinitions() {
      return this.$store.getters['projects/GET_CASE_DEFINITIONS'];
    },
    getProjectCreatorRoles() {
      return this.rolesOptions.filter((role) => this.projectCreatorRoles.includes(role));
    },
  },
  watch: {
    tag: 'loadTags',
  },
  created() {
    // On created
    this.usersOptions = this.existingUsers;
    this.rolesOptions = this.existingProjectRoles;

    if (!this.caseDefinitions || this.caseDefinitions.length <= 0) {
      this.fetchCaseDefinitions();
    }
  },
  mounted() {
    // Set focus on project name field
    this.$refs.projectNameField.$el.focus();
    this.fetchTags();
    this.addCurrentUserToProject();
  },
  methods: {
    fetchCaseDefinitions() {
      return this.$store.dispatch('projects/fetchCaseDefinitions', this);
    },
    addCurrentUserToProject() {
      const currentUserEmail = this.getCurrentUserEmail;
      if (currentUserEmail && currentUserEmail.length > 0) {
        const currentUserInfo = this.usersOptions.find((user) => user.email === currentUserEmail);
        const currentUserRoles = this.getProjectCreatorRoles;
        this.currentUser = {
          ...currentUserInfo,
          roles: currentUserRoles,
        };
        if (currentUserInfo) {
          this.project.users.push({
            ...this.currentUser,
          });
          this.usersOptions = this.usersOptions.filter((u) => u.id !== this.currentUser.id);
        }
      }
    },
    // Tags methods
    loadTags() {
      if (this.tag.length < 1) return;

      const url = `/tags?name=${this.tag.toLowerCase()}&type=${this.tagType}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        api.assess
          .get(url)
          .then((response) => {
            this.autocompleteItems = response.data.map((t) => ({
              text: t.name,
            }));
          })
          .catch((error) => {
            // console.log(error);
            const errorMessage = `Could not Fetch Tags : ${error.response.data.message || ''}`;
            notify.notifyError(errorMessage, this);
          });
      }, 500);
    },
    tagsChanged(newTags) {
      this.autocompleteItems = [];
      this.project.tags = newTags;
    },
    newTagToValidate(obj) {
      const tag = obj.tag.text.toLowerCase();
      if (!this.allTags.map((t) => t.name).includes(tag)) {
        this.createTagRecord(tag);
      }
      if (!this.project.tags.includes(tag)) {
        obj.addTag();
      }
      this.tag = '';
    },
    createTagRecord(tag) {
      const tagPayload = { name: tag, type: this.tagType };
      api.assess
        .post('/tags', tagPayload)
        .then((response) => {
          this.fetchTags();
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Add Tag : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    fetchTags() {
      this.$store.dispatch('tags/fetchProjectTags', this);
    },
    // Participants methods
    setParticipants(users) {
      this.project.users = users;
    },
    removeFromUsersOptions(value) {
      this.usersOptions = this.usersOptions.filter((user) => user.id !== value.id);
    },
    addToUsersOptions(value) {
      this.usersOptions.push(value);
    },
    // Other methods
    hide() {
      this.$emit('closeModal');
    },
    onSubmit() {
      const project = {
        name: this.project.name,
        description: this.project.description,
        type: this.project.type,
        tags: this.project.tags.map((tag) => ({ name: tag.text })),
        driveLink: this.project.driveLink,
        chatLink: this.project.chatLink,
        users: this.project.users.map((user) => ({
          id: user.id,
          displayName: user.displayName,
          email: user.email,
          roles: user.roles.map((role) => role),
        })),
      };
      this.$emit('createProject', project);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-required {
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.5rem;
  font-size: 14px;
}
.participants-autocomplete {
  padding-top: 0rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
.participants-autocomplete-item {
  padding-left: 0rem;
}
.user-entry {
  text-align: left;
}
.buttons-container {
  padding-top: 0.4rem;
}
.no-participant {
  padding-top: 1rem;
  padding-left: 1.2rem;
}
.tiny-button {
  height: 19px;
  min-width: 19px;
  width: 19px;
  top: -0.2rem;
  left: 0.4rem;
}
.tags-field-container {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.tags-field {
  margin-top: 0rem;
}
.tags-label {
  padding-top: 0.8rem;
  padding-left: 0rem;
}
.tags-input {
  padding-left: 0rem;
}
.modal-button {
  margin-right: 0.4rem;
}
.link-field {
  padding-top: 0.4rem;
}
.description-field {
  margin-top: 0.8rem;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.confirm-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.cancel-button {
  color: #fe0600 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #fe0600 !important;
    opacity: 0.8 !important;
    border-color: #fe0600 !important;
  }
}
.indicator-icon {
  margin-right: 25px;
}
</style>
