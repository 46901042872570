<template>
  <div class="project-container md-layout card-view-layout">
    <loader v-if="loading"></loader>
    <div
      v-else
      v-for="project in projects"
      :key="project.id"
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-100"
    >
      <project-overview-card
        :project="project"
        @projectClicked="projectClicked"
      >
      </project-overview-card>
    </div>
  </div>
</template>

<script>
import ProjectOverviewCard from '@/components/Avalia/ProjectsOverviewTabs/ProjectOverviewCard.vue';
import Loader from '@/components/Avalia/Loader/Loader.vue';

export default {
  components: {
    ProjectOverviewCard,
    Loader,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    loading() {
      return this.$store.getters['projects/GET_LOADING'];
    },
    hasProjects() {
      return this.projects.length > 0;
    },
  },
  methods: {
    projectClicked(event) {
      this.$emit('projectClicked', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-view-cards {
  margin-top: 1rem;
}
.project-container {
  display: flex;
  align-items: stretch;
}
</style>
