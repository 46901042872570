<template>
  <div :id="sparklineId" class="sparkline-generated" :style="frameStyle"></div>
</template>

<script>
// import * as d3 from 'd3';
import { select, scaleLinear, line } from 'd3';

export default {
  name: 'sparkline',
  components: {},
  props: {
    sparklineData: Array,
  },
  data() {
    return {
      frame: null,
      svg: null,
      width: null,
      height: null,
      innerWidth: null,
      innerHeight: null,
      instanceUniqueId: null,
      frameStyle: {
        width: '100%',
        height: '100%',
      },
      margin: {
        top: 4,
        right: 4,
        bottom: 4,
        left: 4,
      },
      sparklineColor: '#00d2ff',
    };
  },
  computed: {
    sparklineId() {
      return `sparkline-frame-${this.instanceUniqueId}`;
    },
    sparklineDataLength() {
      return this.sparklineData.length;
    },
  },
  watch: {},
  created() {
    // On created
    // Generate a pseudo random Id needed when multiple instances of a chart are used in a same page (Dashboard)
    this.instanceUniqueId = Math.random().toString(36).substring(7);
  },
  mounted() {
    // On mounted
    const frame = select(`#${this.sparklineId}`);
    // Get width and height from the chart container's div
    this.width = frame.property('clientWidth');
    this.height = frame.property('clientHeight');
    this.innerWidth = this.width - this.margin.left - this.margin.right;
    this.innerHeight = this.height - this.margin.top - this.margin.bottom;
    this.svg = frame.append('svg')
      .attr('height', this.height)
      .attr('width', this.width)
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

    if (this.sparklineData && this.sparklineDataLength > 0) {
      this.drawSparkline();
    }
  },
  methods: {
    drawSparkline() {
      const scaleX = scaleLinear()
        .domain([0, this.sparklineDataLength])
        .range([0, this.innerWidth]);
      const scaleY = scaleLinear()
        .domain([0, 15])
        .range([this.innerHeight, 0])
        .clamp(true);

      const l = line()
        .x((d, i) => scaleX(i))
        .y((d) => scaleY(d));

      this.svg.append('path').datum(this.sparklineData)
        .attr('fill', 'none')
        .attr('stroke', this.sparklineColor)
        .attr('stroke-width', 2)
        .style('stroke-linejoin', 'miter')
        .attr('d', l);

      this.svg.append('circle')
        .attr('r', 3)
        .attr('cx', scaleX(0))
        .attr('cy', scaleY(this.sparklineData[0]))
        .attr('fill', this.sparklineColor);
      this.svg.append('circle')
        .attr('r', 3)
        .attr('cx', scaleX(this.sparklineDataLength - 1))
        .attr('cy', scaleY(this.sparklineData[this.sparklineDataLength - 1]))
        .attr('fill', this.sparklineColor);
    },
  },
};
</script>

<style lang="scss" scoped>
.sparkline-generated{
  width: 100%;
}
</style>
