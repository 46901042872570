function numberToLabel(int) {
  switch (int) {
    case 0:
      return 'low';
    case 1:
      return 'medium';
    case 2:
      return 'high';
    default:
      return '';
  }
}

function utilsGetHealthClass(health) {
  let color = 'primary';
  if (health === 'on-track') {
    color = 'text-success';
  } else if (health === 'at-risk') {
    color = 'text-warning';
  } else if (health === 'off-track') {
    color = 'text-danger';
  }
  return color;
}
function utilsGetHealthClassBackground(health) {
  let color = 'background-primary';
  if (health === 'on-track') {
    color = 'background-success';
  } else if (health === 'at-risk') {
    color = 'background-warning';
  } else if (health === 'off-track') {
    color = 'background-danger';
  }
  return color;
}

function utilsGetHealthClassFromFeedbackValue(health) {
  let color = 'text-primary';//let color = 'feedback-neutral';
  if (health === 'on-track') {
    color = 'text-success';
  } else if (health === 'at-risk') {
    color = 'text-warning';
  } else if (health === 'off-track') {
    color = 'text-danger';
  }
  return color;
}

export {
  numberToLabel,
  utilsGetHealthClass,
  utilsGetHealthClassBackground,
  utilsGetHealthClassFromFeedbackValue,
};
