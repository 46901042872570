<template>
  <div>
    <div v-for="member in team" :key="member.id">
      <user-manager
        :rolesOptions="rolesOptions"
        :users="users"
        @actionUser="editUser"
        @removeUser="removeUser"
        :user="member"
      />
    </div>
    <user-manager
      :rolesOptions="rolesOptions"
      :users="users"
      @actionUser="addUser"
      @removeUser="removeUser"
      :user="currentAddedUser"
    />
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import UserManager from './UserManager.vue';

export default {
  name: 'team-manager',
  components: {
    UserManager,
  },
  props: {
    rolesOptions: Array,
    teamProps: {
      type: Array,
      default: () => [],
    },
    usersOptions: {
      type: Array,
      default: () => [],
    },
    currentUser: Object,
  },
  data() {
    return {
      users: this.usersOptions,
      team: this.teamProps,
      currentAddedUser: {
        displayName: '',
        roles: [],
      },
    };
  },
  mounted() {
    // this.users = this.fetchUser();
  },
  watch: {
    teamProps(newVal) {
      if (newVal) {
        this.team = newVal;
      }
    },
    usersOptions(newVal) {
      if (newVal) {
        this.users = newVal;
      }
    },
  },
  methods: {
    fetchUser() {
      return this.$store.getters['users/GET_USERS'];
    },
    addUser(value) {
      this.removeFromUsersList(value); // Remove from list of users to add to project
      this.currentAddedUser = {
        displayName: '',
        roles: [],
      };
      this.team.push(value);
      this.$emit('setTeam', this.team);
    },
    editUser(value) {
      this.team = this.team.map((member) => {
        if (member.id === value.id) {
          return value;
        }
        return member;
      });
      this.$emit('setTeam', this.team);
    },
    removeUser(value) {
      if (this.currentUser && this.currentUser.id === value.id) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'md-button md-success btn-fill',
            cancelButton: 'md-button md-danger btn-fill',
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
          .fire({
            title: 'Are you sure you want to remove yourself from this project ?',
            text: "You are trying to remove yourself from this project. You won't be able to access this project after his creation.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              this.removeUserFromProject(value);
            }
          });
      } else {
        this.removeUserFromProject(value);
      }
    },
    removeUserFromProject(value) {
      this.addToUsersList(value); // Able to choose the user again in the users list
      this.team = this.team.filter((member) => member.id !== value.id);
      this.$emit('setTeam', this.team);
    },
    removeFromUsersList(value) {
      this.$emit('removeFromUsersList', value);
    },
    addToUsersList(value) {
      this.$emit('addToUsersList', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.team-label {
  text-align: left!important;
}
</style>
<style lang="scss">
.swal2-container {
  z-index: 10000;
}
</style>
