<template>
  <md-card
    class="md-card-tabs nomargin nopadding"
    :class="[
      { 'flex-column': flexColumn },
      { 'nav-pills-icons': navPillsIcons },
      { 'md-card-plain': plain }
    ]"
  >
    <md-card-content class="nopadding">
      <md-list class="custom-nav-tabs nav-tabs">
        <md-list-item
          v-for="(item, index) in tabName"
          :key="item"
          :class="[
            { active: isActivePanel(tabName[index]) },
            { [getColorButton(colorButton)]: isActivePanel(tabName[index]) }
          ]"
          @click="switchPanel(tabName[index])"
        >
          {{ tabName[index] }}
          <md-icon v-if="navPillsIcons">
            {{ tabIcon[index] }}
          </md-icon>
        </md-list-item>
      </md-list>

      <!-- Pagination, Searching and Filtering header -->
      <div
        v-show="showPaginationAndFilters"
        class="md-layout padding-top-controls md-alignment-bottom-left"
      >
        <!-- Searching and Filtering -->
        <div class="md-layout-item md-size-100 md-medium-size-100 nopadding">
          <div class="filtering-card">
            <div class="md-layout md-alignment-bottom-center">
              <div class="md-layout-item md-layout md-size-100">
                <!-- Filter by Type -->
                <div class="md-layout-item md-size-33">
                  <md-field>
                    <md-select
                      class="type-search-select"
                      :value="filters.projectType"
                      name="type"
                      :placeholder="typeSelectPlaceholder"
                      @md-selected="filterTypeChange"
                    >
                      <md-option
                        v-for="item in caseDefinitionsNames"
                        :key="item"
                        :label="item"
                        :value="item"
                        class="noshadow"
                      >
                        {{ item }}
                      </md-option>
                      <md-option
                        :key="allOption.key"
                        :label="allOption.label"
                        :value="allOption.value"
                        >{{ allOption.label }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>

                <!-- Filter by Name -->
                <div class="md-layout-item md-size-33 ">
                  <!-- <label class="md-form-label">Filter by Name</label> -->
                  <md-field>
                    <md-input
                      :value="filters.nameSearch"
                      :placeholder="nameInputPlaceholder"
                      type="search"
                      class="name-search-input"
                      clearable
                      @input="filterNameChange"
                    />
                  </md-field>
                </div>

                <!-- Filter by Tags -->
                <div class="md-layout-item md-size-33 ">
                  <!-- <label class="md-form-label">Filter by Tags</label> -->
                  <md-field>
                    <vue-tags-input
                      class="avalia-tags-input"
                      v-model="tag"
                      :tags.sync="filters.tags"
                      :autocomplete-items="autocompleteItems"
                      :placeholder="tagsInputPlaceholder"
                      :add-only-from-autocomplete="true"
                      @tags-changed="tagsChanged"
                    />
                  </md-field>
                </div>

                <!-- Show Current User's projects (default) or all users projects -->
                <!--
                <div class="md-layout-item md-size-33 filter-by-participant">
                  <md-radio v-model="filters.showMyProjects" :value="true" @change="filterScopeShownChange">My Projects</md-radio>
                  <md-radio v-model="filters.showMyProjects" :value="false" @change="filterScopeShownChange">All Projects</md-radio>
                </div> -->
                <!-- Show Archived and Reset Search -->
                <div class="md-layout-item md-size-33 filter-by-archived">
                  <md-checkbox v-model="filters.showArchived" @change="filterArchivedChange">Show archived</md-checkbox>
                </div>

              </div>
              <!--
              <div class="md-layout-item md-layout md-size-10 md-alignment-bottom-center">
                <div class="md-layout-item md-size-100">
                  <md-button class="md-info md-sm" @click="resetSearch">Reset</md-button>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>

      <!-- Tab content -->
      <transition name="fade" mode="out-in">
        <div class="tab-content">
          <template v-for="(item, index) in tabName">
            <template v-if="isActivePanel(tabName[index])">
              <div :key="item" :class="getTabContent(index + 1)" class="full-size-tab">
                <slot :name="getTabContent(index + 1)">
                  This is the default text!
                </slot>
              </div>
            </template>
          </template>
        </div>
      </transition>
    </md-card-content>

    <!-- Pagination footer -->
    <md-card-actions
      v-show="showPaginationAndFilters"
      md-alignment="space-between"
      class="pagination-container"
    >
      <!-- Pagination: Per Page -->
      <div class="md-layout-item md-size-15 md-medium-size-15 nopadding">
        <div class="pagination-card">
          <md-content class="pagination-card-content">
            <div class="md-layout pagination-layout md-alignment-center-space-between">
              <div
                class="md-layout-item md-size-100 per-page-container md-alignment-center-space-around"
              >
                <md-field>
                  <label for="pages">Per page</label>
                  <md-select
                    :value="pagination.perPage"
                    name="pages"
                    @md-selected="nbProjectPerPageChange"
                  >
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                      class="noshadow"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </md-content>
        </div>
      </div>
      <div>
        <p class="card-category">
          Showing {{ total === 0 ? 0 : from + 1 }} to {{ to }} of {{ total }} projects
        </p>
      </div>
      <pagination
        :value="pagination.currentPage"
        class="pagination-info"
        :per-page="pagination.perPage"
        :total="total"
        @input="(page) => paginationChange(page)"
      />
    </md-card-actions>
  </md-card>
</template>

<script>
import { debounce } from 'lodash';
import { Pagination } from '@/components/Template';
import VueTagsInput from '@johmun/vue-tags-input';

import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

export default {
  name: 'CustomTabs',
  components: {
    Pagination,
    VueTagsInput,
  },
  props: {
    flexColumn: Boolean,
    navPillsIcons: Boolean,
    plain: Boolean,
    tabName: Array,
    tabIcon: Array,
    colorButton: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activePanel: this.tabName[0],
      tagType: 'project tag',
      tagsInputPlaceholder: 'Filter by tags...',
      nameInputPlaceholder: 'Filter by name...',
      typeSelectPlaceholder: 'Filter by type...',
      tag: '',
      autocompleteItems: [],
      allOption: {
        key: 'all',
        label: 'All',
        value: '',
      },
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.$store.getters['projects/GET_PROJECTS_TOTAL'];
    },
    showPaginationAndFilters() {
      if (this.activePanel === 'Card View') {
        return true;
      }
      if (this.activePanel === 'List View') {
        return true;
      }
      return false;
    },
    caseDefinitions() {
      return this.$store.getters['projects/GET_CASE_DEFINITIONS'];
    },
    caseDefinitionsNames() {
      return this.caseDefinitions.map((caseDefinition) => caseDefinition.name);
    },
    currentUser() {
      return this.$store.getters['users/GET_CURRENT_USER'];
    },
    pagination() {
      return this.$store.getters['projectsFiltersAndPagination/GET_PAGINATION'];
    },
    filters() {
      return this.$store.getters['projectsFiltersAndPagination/GET_FILTERS'];
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetchProjects();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.fetchProjects();
      },
      deep: true,
    },
    tag: {
      handler() {
        this.loadTags();
      },
    },
  },
  created() {
    // On created
    if (!this.caseDefinitions || this.caseDefinitions.length <= 0) {
      this.fetchCaseDefinitions();
    }
    this.fetchProjectsQuery();
  },
  mounted() {
  },
  methods: {
    fetchCaseDefinitions() {
      return this.$store.dispatch('projects/fetchCaseDefinitions', this);
    },
    // Using lodash debounce to avoid executing a search request on each character type in the Filter By Name field
    fetchProjects: debounce(
      function deb() {
        this.fetchProjectsQuery();
      },
      500,
    ),
    fetchProjectsQuery() {
      this.$store.commit('projects/SET_LOADING', true);

      let users = '';
      if (this.filters.showMyProjects) {
        users += this.currentUser.sub;
      }
      const payload = {
        tags: this.filters.tags,
        name: this.filters.nameSearch,
        projectType: this.filters.projectType,
        showArchived: this.filters.showArchived,
        users,
        currentPage: this.pagination.currentPage,
        perPage: this.pagination.perPage,
        vm: this,
      };
      this.$store.dispatch('projects/fetchProjects', payload)
        .then(() => this.$store.commit('projects/SET_LOADING', false));
    },
    switchPanel(panel) {
      this.activePanel = panel;
    },
    isActivePanel(panel) {
      return this.activePanel === panel;
    },
    getColorButton(colorButton) {
      return `md-${colorButton}`;
    },
    getTabContent(index) {
      return `tab-pane-${index}`;
    },
    // Tags methods
    loadTags() {
      if (this.tag.length < 1) return;

      const url = `/tags?name=${this.tag.toLowerCase()}&type=${this.tagType}`;

      clearTimeout(this.debounce);
      // Using a custom debounce function to avoid executing tag fetching request on each character typed
      this.debounce = setTimeout(() => {
        api.assess
          .get(url)
          .then((response) => {
            this.autocompleteItems = response.data.map((t) => ({
              text: t.name,
            }));
          })
          .catch((error) => {
            // console.log(error);
            const errorMessage = `Could not Fetch Project Tags : ${error.response.data.message || ''}`;
            notify.notifyError(errorMessage, this);
          });
      }, 500);
    },
    tagsChanged(newTags) {
      this.autocompleteItems = [];
      this.tags = newTags;
    },
    resetSearch() {
      this.$store.commit('projectsFiltersAndPagination/RESET_FILTERS_AND_PAGINATION');
    },
    filterTypeChange(projectType) {
      this.$store.commit('projectsFiltersAndPagination/SET_FILTERS_PROJECT_TYPE', projectType);
    },
    filterNameChange(projectName) {
      this.$store.commit('projectsFiltersAndPagination/SET_FILTERS_NAME', projectName);
    },
    filterTagsChange(tags) {
      this.$store.commit('projectsFiltersAndPagination/SET_FILTERS_TAGS', tags);
    },
    filterScopeShownChange(isMyProjectShown) {
      this.$store.commit('projectsFiltersAndPagination/SET_FILTERS_OWN_PROJECTS', isMyProjectShown);
    },
    filterArchivedChange(isArchivedShown) {
      this.$store.commit('projectsFiltersAndPagination/SET_FILTERS_ARCHIVED', isArchivedShown);
    },
    paginationChange(numPage) {
      this.$store.commit('projectsFiltersAndPagination/SET_PAGINATION_CURRENT_PAGE', numPage);
    },
    nbProjectPerPageChange(nbProjectPerPage) {
      this.$store.commit('projectsFiltersAndPagination/SET_PAGINATION_PER_PAGE', nbProjectPerPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-nav-tabs {
  justify-content: left !important;
}
.full-size-tab {
  flex: 1;
}
.tab-content {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.pagination-layout {
  padding: 0rem 0rem 0rem 0rem;
}
.per-page-container {
  padding: 0rem 0rem 0.6rem;
}
.pagination-container {
  padding-right: 6rem;
  padding-top: 0rem;
}
.pagination-card {
  //margin: 1rem 15px 0rem;
  z-index: 0;
  //padding-left: 15px;
}
.pagination-card-content {
  padding: 0rem 1.25rem 0rem;
}
.padding-top-controls {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
}
.filter-by-archived {
  text-align: left;
}
.filter-by-participant {
  text-align: left;
}
.name-search-input::placeholder {
  font-size: 0.89rem !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
.type-search-select {
  &::v-deep .md-input::placeholder {
    font-size: 0.89rem !important;
    color: rgba(0, 0, 0, 0.5) !important;
  }
}
.content-container {
  position: relative;
  min-height: calc(100vh - (#{$content-padding-vertical} +
    #{$toolbar-minheight} +
    #{$toolbar-padding-vertical} +
    #{$footer-minheight} +
    #{$footer-padding-vertical}));
}
</style>
