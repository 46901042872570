var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('autocomplete',{ref:"autocomplete",attrs:{"search":_vm.search,"get-result-value":_vm.getResultValue,"aria-label":"Search for a member of the team of the project","auto-select":"","default-value":_vm.value},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var rootProps = ref.rootProps;
    var inputProps = ref.inputProps;
    var inputListeners = ref.inputListeners;
    var resultListProps = ref.resultListProps;
    var resultListListeners = ref.resultListListeners;
    var results = ref.results;
    var resultProps = ref.resultProps;
return [_c('div',_vm._b({},'div',rootProps,false),[_c('div',{staticClass:"form-field"},[_c('div',{staticClass:"form-field__control"},[_c('input',_vm._g(_vm._b({staticClass:"form-field__input",attrs:{"type":"text","placeholder":" ","disabled":_vm.disabled},domProps:{"value":inputProps.value},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur}},'input',inputProps,false),inputListeners)),_c('label',{staticClass:"form-field__label",attrs:{"for":"autocomplete-participant"}},[_vm._v("Participant")]),_c('div',{staticClass:"form-field__bar"})])]),(_vm.noResults)?_c('ul',{staticClass:"autocomplete-result-list",staticStyle:{"position":"absolute","z-index":"1","width":"100%","top":"100%"}},[_c('li',{staticClass:"autocomplete-result"},[_vm._v(" No results found ")])]):_vm._e(),_c('ul',_vm._g(_vm._b({},'ul',resultListProps,false),resultListListeners),_vm._l((results),function(result,index){return _c('li',_vm._b({key:resultProps[index].id},'li',resultProps[index],false),[_vm._v(" "+_vm._s(result.displayName)+" : "+_vm._s(result.email)+" ")])}),0)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }