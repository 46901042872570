<template>
  <div class="project-overview-container">
    <div class="md-layout">
      <div class="md-layout-item md-size-100 nopadding">
        <create-project-modal
          v-if="showCreateProjectModal"
          :existingUsers="existingUsers"
          :existingProjectRoles="existingProjectRoles"
          @closeModal="showCreateProjectModal = false"
          @createProject="onCreateProject"
          class="create-project-modal"
        >
        </create-project-modal>

        <div class="md-layout">
          <div
            class="md-layout-item md-size-100 mx-auto md-medium-size-100 nopadding"
          >
            <projects-overview-tabs
              :tab-name="['Card View', 'List View', 'Slides']"
              :tab-icon="['view_module', 'list', 'ondemand_video']"
              class="page-subcategories content-container"
              nav-pills-icons
              plain
              color-button="info"
            >
              <template slot="tab-pane-1">
                <card-view-tab
                  @projectClicked="projectClicked"
                  :projects="projects"
                />
              </template>
              <template slot="tab-pane-2">
                <table-view-tab
                  @projectClicked="projectClicked"
                  :projects="projects"
                ></table-view-tab>
              </template>
              <template slot="tab-pane-3">
                <slides-view-tab />
              </template>
            </projects-overview-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="add-project-button-container-fullsize">
      <div class="add-project-button-container-sticky">
        <md-button
            class="md-info md-round md-just-icon add-project-button"
            @click="showCreateProjectModal = true"
          >
            <md-icon class="md-size-2x">add</md-icon>
            <md-tooltip md-direction="left" md-delay="300">Add New Project</md-tooltip>
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import ProjectsOverviewTabs from '@/components/Avalia/ProjectsOverviewTabs/ProjectsOverviewTabs.vue';
import CardViewTab from '@/components/Avalia/ProjectsOverviewTabs/CardViewTab.vue';
import TableViewTab from '@/components/Avalia/ProjectsOverviewTabs/TableViewTab.vue';
import SlidesViewTab from '@/components/Avalia/ProjectsOverviewTabs/SlidesViewTab.vue';
import CreateProjectModal from '@/components/Avalia/Modals/CreateProjectModal.vue';
import notify from '@/utils/notificationsUtils';

export default {
  components: {
    ProjectsOverviewTabs,
    CreateProjectModal,
    CardViewTab,
    TableViewTab,
    SlidesViewTab,
  },
  data() {
    return {
      showCreateProjectModal: false,
      currentProjectId: null,
    };
  },
  computed: {
    projects() {
      return this.$store.getters['projects/GET_PROJECTS'];
    },
    existingUsers() {
      return this.$store.getters['users/GET_USERS'];
    },
    existingProjectRoles() {
      return this.$store.getters['projects/GET_POSSIBLE_ROLES'];
    },
  },
  created() {
    // projects fetching is done in projects-overview-tabs where pagination and filtering is handled
    this.fetchAllProjectRoles();
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      this.$store.dispatch('users/fetchUsers', this);
    },
    fetchAllProjectRoles() {
      this.$store.dispatch('projects/fetchAllProjectRoles', this);
    },
    onShowCreateProjectModal() {
      this.showCreateProjectModal = true;
    },
    onCreateProject(project) {
      api.assess
        .post('/projects', project)
        .then((response) => {
          this.showCreateProjectModal = false;
          if (response.data) {
            this.currentProjectId = response.data;
            const successMessage = 'Project successfully Created';
            notify.notifySuccess(successMessage, this);
          }
        })
        .then(() => {
          this.$router.push({
            name: 'Project Details',
            params: { projectId: this.currentProjectId },
          });
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Create Project : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    projectClicked({ event, project }) {
      this.$track('fe_viewProjectDetails', project);

      const location = {
        name: 'Project Details',
        params: { projectId: project.id },
      };
      if (event.metaKey || event.ctrlKey) {
        const routeData = this.$router.resolve(location);
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(location);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
//COMPONENT
.project-overview-container {
  position: relative;
  min-height: calc(100vh - (#{$content-padding-vertical} +
  #{$toolbar-minheight} +
  #{$toolbar-padding-vertical} +
  #{$footer-minheight} +
  #{$footer-padding-vertical}));
}
.project-overview-footer {
  text-align: right;
}
.add-project-button {
  // Fixed button
  // margin-right: 18px;
  // Floating button
  width: 60px;
  height: 60px;
  animation: .4s jump ease 5 alternate;
}

@keyframes jump {
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0,0,0,.15);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
  }
}
.add-project-button-container-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 200;
  padding-bottom: 15px;
  bottom: 0;
  align-self: flex-end;
}
.add-project-button-container-fullsize {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  bottom: 0;
}
.content-container {
  position: relative;
  min-height: calc(100vh - (#{$content-padding-vertical} +
    #{$toolbar-minheight} +
    #{$toolbar-padding-vertical} +
    #{$footer-minheight} +
    #{$footer-padding-vertical}));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-view-layout {
  padding-bottom: 1.6rem;
}
</style>
