<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-card v-if="hasProjects" class="table-view-cards">
        <md-card-content>
          <loader v-if="loading"></loader>
          <md-table
            v-else
            :value="projects"
            class="paginated-table table-striped table-hover custom-table"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              @click.native="projectClicked(item, $event)"
              :class="{ clicknotallowed: !isCurrentUserParticipant(item) }"
            >
              <md-table-cell md-label="Name" class="table-cell-name">
                {{ item.name }}
              </md-table-cell>

              <md-table-cell class="table-cell-archived custom-table-cell">
                <div class="archived-icon-container">
                  <i v-if="item.isArchived" class="fas fa-archive fa-lg" />
                </div>
              </md-table-cell>

              <md-table-cell md-label="Type" class="table-cell-type">
                {{ item.caseType }} v{{ item.caseVersion }}
              </md-table-cell>

              <md-table-cell md-label="State" class="table-cell-state custom-table-cell">
                <span class="state-container">
                  <md-icon class="text-info"
                    ><i class="fa" :class="getStateIcon(item)"></i
                  ></md-icon>
                  <h6 class="state-name">
                    {{ item.currentState || "completed" }}
                  </h6>
                </span>
              </md-table-cell>

              <md-table-cell md-label="Health" class="table-cell-health custom-table-cell">
                <span class="health-container">
                  <md-icon :class="getHealthClass(item)"><i class="fa fa-heartbeat"></i></md-icon>
                  <h6 class="health-name">
                    {{ item.health }}
                  </h6>
                </span>
              </md-table-cell>

              <md-table-cell md-label="Tags" class="table-cell-tags custom-table-cell">
                <div v-if="item.tags.length">
                  <md-chip
                    class="md-info custom-chip"
                    v-for="tag in item.tags"
                    :key="tag.name"
                    md-disabled
                    >{{ tag.name }}</md-chip
                  >
                </div>
                <div v-else>
                  <div class="discrete">no tag</div>
                </div>
              </md-table-cell>
              <md-table-cell
                md-label="Participants"
                class="table-cell-participants custom-table-cell"
              >
                <div v-if="item.users.length">
                  <md-chip
                    class="md-primary custom-chip"
                    v-for="user in item.users"
                    :key="user.email"
                    md-disabled
                    >{{ user.displayName }}</md-chip
                  >
                </div>
                <div v-else>
                  <div class="discrete">no participant</div>
                </div>
              </md-table-cell>

              <md-table-cell
                md-label="Last Update"
                class="table-cell-last-update custom-table-cell"
              >
                {{ item.lastUpdate | moment("from") }}
              </md-table-cell>

              <md-table-cell md-label="Project Pulse" class="table-cell-sparkline">
                <div class="sparkline-container">
                <sparkline :sparklineData="item.sparkline"></sparkline>
                </div>
              </md-table-cell>

            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { utilsGetHealthClass } from '@/utils/utils';
import Sparkline from '@/components/Avalia/ProjectsOverviewTabs/Sparkline/Sparkline.vue';
import Loader from '@/components/Avalia/Loader/Loader.vue';
import notify from '@/utils/notificationsUtils';

export default {
  name: 'projects-overview-table',
  components: {
    Sparkline,
    Loader,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.$store.getters['projects/GET_LOADING_PROJECTS'];
    },
    hasProjects() {
      return this.projects.length > 0;
    },
    currentUser() {
      return this.$store.getters['users/GET_CURRENT_USER'];
    },
  },
  watch: {},
  methods: {
    projectClicked(project, e) {
      if (this.isCurrentUserParticipant(project)) {
        this.$emit('projectClicked', {
          event: e,
          project,
        });
      } else {
        const warningMessage = 'You can\'t view the details of a project you\'re not participating in';
        notify.notifyWarning(warningMessage, this);
        e.stopPropagation();
      }
    },
    getStateIcon(project) {
      return project.currentStateIcon || 'fa-trophy';
    },
    getHealthClass(project) {
      return utilsGetHealthClass(project.health);
    },
    isCurrentUserParticipant(project) {
      return project.users.map((user) => user.email).includes(this.currentUser.sub);
    },
  },
};
</script>

<style lang="scss" scoped>
.state-container {
  display: -webkit-box;
  /*
  justify-content: flex-start;

  margin-bottom: 0.4rem;
  */
}
.health-container {
  display: -webkit-box;
}
.state-name {
  margin: 0rem;
  padding-left: 0.5rem;
}

.health-name {
  margin: 0rem;
  padding-left: 0.5rem;
}

.custom-table-cell {
  padding-left: 1rem !important;
}
.table-view-cards {
  margin-top: 1rem;
}

.table-cell-name {
  width: 8%;
}
.table-cell-type {
  width: 8%;
}
.table-cell-state {
  width: 12%;
}
.table-cell-health {
  width: 12%;
}
.table-cell-archived {
  width: 2%;
}
.table-cell-tags {
  width: 20%;
}
.table-cell-participants {
  width: 24%;
}
.table-cell-last-update {
  width: 4%;
}
.table-cell-sparkline {
  width: 10%;
}
.archived-icon-container {
  //margin-left: 1.5rem;
}
.md-table-row {
  cursor: pointer;
}
.custom-chip {
  margin-left: 0px;
  margin-right: 4px;
}
.discrete {
  color: rgba(87, 87, 87, 1);
}
.custom-table {
  &::v-deep th:not(:first-child) {
    padding-left: 1rem !important;
  }
}
.sparkline-container {
  //margin: 0 auto;
  height: 2.5rem;
  width: 8rem;
}
 .paginated-table .md-table-head:last-child {
       justify-content: flex-start !important;
 }
</style>
